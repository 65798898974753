import { reactive, watch } from 'vue';
import mitt from 'mitt';
import _mergeWith from 'lodash/mergeWith';
import _isArray from 'lodash/isArray';
import _cloneDeep from 'lodash/cloneDeep';
import _uniq from 'lodash/uniq';

import { Accessor } from './Accessor';
import { IStore } from './runtime/IStore';
import { IOptions } from './runtime/IOptions';
import { Router } from './runtime/Router';
import { HttpService } from './runtime/HttpService';
import { Translations } from './runtime/Translations';
import { IRouteData } from './runtime/IRouteData';
import { CommandExecutor } from './runtime/CommandExecutor';
import { ICommand } from '../../common/api/runtime/ICommand';
import { DefaultOptions } from './configuration/DefaultOptions';
import { ComponentName } from './configuration/components/ComponentName';
import { DataType } from './configuration/application/DataType';
import LoggerService from '@/common/services/LoggerService';
import { CatalogProductSearchDataTypeEnum } from '@/common/services/swagger/index.defs';
import { IStyle } from '@/common/interfaces/IStyle';
import { ICatalogsInstance } from './ICatalogsInstance';
import { InstanceType } from '../../common/api/runtime/IInstance';
import { ProductSelection } from '@/common/api/runtime/ProductSelection';

export class Instance implements ICatalogsInstance {
  public instanceType: InstanceType = 'Catalogs';
  public props: { [key: string]: any } = {};
  public httpService?: HttpService;
  public store: IStore = reactive({
    notifications: [],
    data: { isInitialized: false, actions: { current: null, data: null } },
    options: { isInitialized: false },
  });
  public router = new Router(this);
  public translations = new Translations(this);
  public commandExecutor = new CommandExecutor(this);
  public logger = LoggerService;

  public registeredComponents = reactive(new Set<ComponentName>());
  public dataTypesRequired = reactive(new Set<DataType>());

  public eventBus = mitt();
  private eventBusExt = mitt();

  public selection = new ProductSelection(this);

  constructor(public id: string, public accessor: Accessor) {
    this.eventBus.on('*', (type, data: unknown) => {
      // dispatch external events, some events are filtered out
      if (!type.toString().startsWith('internal:')) {
        this.eventBusExt.emit(type, data);
      }
    });
  }

  public isInitialized(): boolean | undefined {
    return this.store.data.isInitialized;
  }

  public reload(): void {
    this.router.setRoute(this.router.routeData);
    this.translations.load();
  }

  public setRoute(routeData: IRouteData): void {
    this.router.setRoute(routeData);
  }

  public async init(optionsOverrides: IOptions, routeData?: IRouteData): Promise<void> {
    if (this.store.data.isInitialized) {
      throw new Error(`Instance "${this.id}" is already initialized`);
    }

    const currentOptions: IOptions = this.store.options.isInitialized
      ? this.store.options
      : new DefaultOptions();

    _mergeWith(currentOptions, optionsOverrides, (a, b) => (_isArray(b) ? b : undefined));

    if (currentOptions.inheritStyles) {
      currentOptions.styles = [...(currentOptions.styles ?? []), ...this.getDocumentStyles()];
    }

    this.store.options = currentOptions;
    this.store.options.isInitialized = true;

    this.httpService = new HttpService(this, currentOptions.baseUrl, currentOptions.accessToken);
    this.store.data.isInitialized = true;

    this.router.setRoute(routeData);
    try {
      await this.translations.load();
    } catch (err) {
      this.store.data.isInitialized = false;
      alert('PIS initialization error.');
    }
  }

  public update(optionsOverrides: IOptions, routeData?: IRouteData): void {
    const currentOptions: IOptions = this.store.options.isInitialized
      ? this.store.options
      : new DefaultOptions();

    _mergeWith(currentOptions, optionsOverrides, (a, b) => (_isArray(b) ? b : undefined));

    if (currentOptions.inheritStyles) {
      currentOptions.styles = [...(currentOptions.styles ?? []), ...this.getDocumentStyles()];
    }

    this.store.options = currentOptions;
    this.store.options.isInitialized = true;

    if (routeData) {
      this.router.setRoute(routeData);
    }
  }

  public on(name: string, handler: (type: string, data: unknown) => void): void {
    this.eventBusExt.on(name, handler as never);
  }

  public onReady(callback: () => any): void {
    const unwatch = watch(
      () => this.store.data,
      ({ isInitialized }) => {
        if (isInitialized) {
          callback();
          unwatch();
        }
      },
      { deep: true },
    );
  }

  public off(name?: string | undefined, handler?: (type: string, data: unknown) => void): void {
    if (name) {
      this.eventBusExt.off(name, handler as never);
    } else {
      this.eventBusExt.all.clear();
      // this.intercept = undefined;
    }
  }

  public registerComponent(componentName: ComponentName): void {
    this.registeredComponents.add(componentName);
  }

  public registerRequiredDataTypes(dataTypes: DataType[]): void {
    dataTypes.forEach((dataType) => this.dataTypesRequired.add(dataType));
  }

  public getRegisteredSearchDataTypes(): CatalogProductSearchDataTypeEnum[] {
    if (this.dataTypesRequired && this.dataTypesRequired.size) {
      const searchDataTypes = Object.values(CatalogProductSearchDataTypeEnum);

      const arr = Array.from(this.dataTypesRequired).filter((dataType) =>
        searchDataTypes.includes(dataType as CatalogProductSearchDataTypeEnum),
      ) as CatalogProductSearchDataTypeEnum[];

      return _uniq(arr);
    } else {
      return [];
    }
  }

  public destroy(): void {
    return this.accessor.destroyInstance(this.id);
  }

  public execute<ReturnType = unknown>(
    command: ICommand<Instance, ReturnType>,
  ): Promise<ReturnType | undefined> {
    return this.commandExecutor.execute<ReturnType>(command);
  }

  public getDocumentStyles(): IStyle[] {
    return [...document.styleSheets].map((sheet) => {
      if (sheet.href) {
        return { url: sheet.href };
      } else {
        try {
          return { css: [...sheet.cssRules].map((rule) => rule.cssText).join('') };
        } catch (e) {
          return {};
        }
      }
    });
  }

  public getDefaultOptions(): IOptions {
    return new DefaultOptions();
  }

  public getOptions(): IOptions {
    return this.store.options;
  }

  public getRouteData(): IRouteData {
    return _cloneDeep(this.router.routeData);
  }
}
