
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { defineComponent, PropType } from 'vue';
import Modal from '@/common/components/Modal.vue';
import { CatalogProductItem } from '@/common/services/swagger/index.defs';
import ItemCatalogsDetails from './ItemCatalogsDetails.vue';
export default defineComponent({
  components: { Modal, ItemCatalogsDetails },
  emits: ['hidden'],
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    item: { type: Object as PropType<CatalogProductItem> },
    instanceId: String,
    show: Boolean,
    allCatalogs: { type: Boolean, default: false },
  },
  setup() {
    return {};
  },
});
