
import { Ref, computed, defineComponent, ref } from 'vue';

import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import {
  ProductsPageSizeCommand,
  ProductsSortByCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { CatalogProductSortFieldEnum } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import PaginationAmount from '@/common/components/PaginationAmount.vue';
import useCatalogActions from '@/catalogs/composables/useCatalogActions';
import { Instance } from '@/catalogs/api/Instance';
import DropdownPopper from '@/common/components/DropdownPopper.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { IMenuItem } from '@/catalogs/api/configuration/components/IExportMenuOptions';

export default defineComponent({
  name: ComponentName.aliasesSearchToolbar,

  components: {
    InjectStyles,
    PaginationAmount,
    DropdownPopper,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props, { emit }) {
    const root = ref<HTMLElement>();
    const exportMenuOpen = ref(false);
    const sortByItems = CatalogProductSortFieldEnum;

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      t,
      widerThan,
      narrowerThan,
    } = setupComponent(root, props.instanceId);

    const { onAliasListMenuAction } = useCatalogActions(instance as Ref<Instance>, t);

    const totalCount = computed(() => store.value?.data.products?.totalCount);
    const pagination = computed(() => routeData.value?.products.pagination);
    const sortBy = computed({
      get: () => routeData.value?.products.sortField,
      set: (sortField: any) => instance.value?.execute(new ProductsSortByCommand({ sortField })),
    });
    const pageSize = computed({
      get: () => pagination.value?.pageSize ?? 24,
      set: (pageSize: number) => instance.value?.execute(new ProductsPageSizeCommand({ pageSize })),
    });
    const pageSizeItems = computed(
      () => store.value?.options.components?.productsSearchToolbar?.pageSizes,
    );
    const toolbarVisible = computed(
      () => store.value?.options.components?.productsSearchToolbar?.visible,
    );
    const aliasListMenu = computed(() => store.value?.options.components?.actions?.aliasListMenu);

    const aliasListMenuItems = (): IMenuItem<void>[] =>
      (aliasListMenu.value?.items || []).filter((item: IMenuItem<void>) => {
        if (typeof item.visible === 'function') {
          return item.visible();
        }
        return item.visible;
      });

    const isDisabled = (item: IMenuItem<void>) =>
      typeof item.disabled === 'function' ? item.disabled() : item.disabled;

    const actionsEnabled = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.aliasActionModals) &&
        aliasListMenu.value?.visible,
    );

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      routeData,
      toolbarVisible,
      totalCount,
      pagination,
      sortByItems,
      sortBy,
      pageSizeItems,
      pageSize,
      exportMenuOpen,
      aliasListMenu,
      actionsEnabled,
      instance,
      t,
      emit,
      widerThan,
      isDisabled,
      narrowerThan,
      aliasListMenuItems,
      onAliasListMenuAction,
    };
  },
});
