
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import Pagination from '@/common/components/Pagination.vue';
import { CatalogsPaginateCommand } from '@/catalogs/api/runtime/CommandExecutor';
import PaginationAmount from '@/common/components/PaginationAmount.vue';
import InjectStyles from '@/common/components/InjectStyles.vue';

export default defineComponent({
  name: ComponentName.searchPagination,

  components: {
    Pagination,
    PaginationAmount,
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isWebComponent, isReady, instance, store, routeData, t, widerThan } =
      setupComponent(root, props.instanceId, [DataType.Catalogs]);

    const totalCount = computed(() => store.value?.data.catalogs?.totalCount ?? 0);
    const pagination = computed(() => routeData.value?.catalogs.pagination);

    const changePage = (page: number) => {
      if (instance.value) {
        instance.value.execute(new CatalogsPaginateCommand({ page }));
      }
    };

    return {
      root,
      componentName,
      isWebComponent,
      isReady,
      store,
      totalCount,
      pagination,
      routeData,
      t,
      widerThan,
      changePage,
    };
  },
});
