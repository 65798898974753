
import { defineComponent, PropType, ref } from 'vue';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { ConfiguredProductsCommand } from '@/catalogs/api/runtime/CommandExecutor';
import {
  CatalogDetail,
  QuantityItem,
  ConfigurationItem,
} from '@/common/services/swagger/index.defs';

import PIS from '@/common/api/PIS';
import { Instance } from '@/catalogs/api/Instance';
import Icon from '@/common/components/Icon.vue';
export default defineComponent({
  components: { Icon },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    qty: { type: Object as PropType<QuantityItem>, required: true },
    configurationId: { type: String, required: true },
    productId: { type: String, required: true },
    catalog: { type: Object as PropType<CatalogDetail>, required: true },
  },
  setup(props) {
    const instance = PIS.Catalogs.getInstance(props.instanceId) as Instance;
    const expanded = ref(false);
    const hasBeenLoaded = ref<boolean>(false);
    const configurator = ref<ConfigurationItem | undefined>();

    const loadConfiguredOptions = async () => {
      try {
        expanded.value = !expanded.value;
        if (!hasBeenLoaded.value && props.catalog?.id) {
          const result: ConfigurationItem | undefined = await instance.commandExecutor.execute(
            new ConfiguredProductsCommand({
              catalogId: props.catalog.id,
              configurationId: props.configurationId,
            }),
          );
          if (result) {
            configurator.value = result;
          }
          hasBeenLoaded.value = true;
        }
      } catch {
        // Ignore error
      }
    };

    return { loadConfiguredOptions, configurator, expanded, hasBeenLoaded };
  },
});
