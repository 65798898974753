import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { class: "d-flex flex-row justify-content-between align-items-center flex-wrap w-100" }
const _hoisted_3 = ["value"]
const _hoisted_4 = ["textContent", "value"]
const _hoisted_5 = { class: "my-3 d-flex flex-row justify-content-between align-items-center flex-wrap" }
const _hoisted_6 = {
  key: 0,
  class: "my-2 mx-2"
}
const _hoisted_7 = {
  key: 1,
  class: "list-unstyled d-flex justify-content-center my-2 mx-2"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  type: "button",
  class: "btn btn-primary dropdown-toggle"
}
const _hoisted_10 = { class: "dropdown-menu show" }
const _hoisted_11 = {
  key: 0,
  class: "dropdown-divider"
}
const _hoisted_12 = {
  key: 1,
  class: "dropdown-item text-muted"
}
const _hoisted_13 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_PaginationAmount = _resolveComponent("PaginationAmount")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_DropdownPopper = _resolveComponent("DropdownPopper")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.toolbarVisible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", {
              class: _normalizeClass(["d-flex align-items-center my-1", [_ctx.narrowerThan('sm') && 'w-100']])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["text-nowrap", [_ctx.narrowerThan('sm') && 'w-100']])
              }, _toDisplayString(_ctx.t('SortBy')) + ":", 3),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sortBy) = $event)),
                class: _normalizeClass(["form-select ms-2", [_ctx.narrowerThan('xl') && 'w-100']])
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sortByItems, (sortName, sortKey) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: sortKey,
                    value: sortKey
                  }, _toDisplayString(_ctx.t(`SortBy${sortName}`)), 9, _hoisted_3))
                }), 128))
              ], 2), [
                [_vModelSelect, _ctx.sortBy]
              ])
            ], 2),
            _createElementVNode("label", {
              class: _normalizeClass(["d-flex align-items-center my-1", [_ctx.narrowerThan('sm') && 'w-100']])
            }, [
              _createElementVNode("span", {
                class: _normalizeClass(["text-nowrap", [_ctx.narrowerThan('sm') && 'w-100']])
              }, _toDisplayString(_ctx.t('PageSize')) + ":", 3),
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize) = $event)),
                class: "form-select ms-2"
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageSizeItems, (size) => {
                  return (_openBlock(), _createElementBlock("option", {
                    textContent: _toDisplayString(size),
                    key: size,
                    value: size
                  }, null, 8, _hoisted_4))
                }), 128))
              ], 512), [
                [_vModelSelect, _ctx.pageSize]
              ])
            ], 2)
          ]),
          _createElementVNode("div", _hoisted_5, [
            (_ctx.totalCount && _ctx.pagination)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_PaginationAmount, {
                    "total-count": _ctx.totalCount,
                    page: _ctx.pagination.page,
                    "page-size": _ctx.pagination.pageSize,
                    "showing-text": _ctx.t('PaginationShowing', true),
                    "of-text": _ctx.t('PaginationOf', true),
                    class: _normalizeClass([_ctx.narrowerThan('sm') && 'w-100 text-center'])
                  }, null, 8, ["total-count", "page", "page-size", "showing-text", "of-text", "class"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.visibleDisplayModes?.length && _ctx.visibleDisplayModes.length > 1)
              ? (_openBlock(), _createElementBlock("ul", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visibleDisplayModes, (mode) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: mode.type
                    }, [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.isModeActive(mode) ? 'div' : 'Clickable'), {
                        class: _normalizeClass(["d-flex align-items-center py-2 mx-2 text-body text-decoration-none", [_ctx.isModeActive(mode) && 'active border-bottom border-primary border-2']]),
                        role: "tab",
                        "aria-selected": _ctx.isModeActive(mode),
                        title: `${_ctx.t('ChangeDisplayModeTo')} ${_ctx.t(mode.tName)}`,
                        onClick: _withModifiers(($event: any) => (_ctx.changeDisplayMode(mode.type)), ["prevent"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Icon, {
                            glyph: mode.icon,
                            class: "mx-1"
                          }, null, 8, ["glyph"]),
                          (_ctx.widerThan('sm'))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.t(mode.tName)), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["class", "aria-selected", "title", "onClick"]))
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.actionsEnabled && _ctx.productListMenu?.items)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: _normalizeClass(["d-flex my-1", [_ctx.narrowerThan('sm') && 'w-100 justify-content-end']])
                }, [
                  _createVNode(_component_DropdownPopper, {
                    placement: "bottom-start",
                    interactive: true
                  }, {
                    trigger: _withCtx(() => [
                      (_ctx.productListMenu.buttonTemplate)
                        ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                            key: 0,
                            template: _ctx.productListMenu.buttonTemplate,
                            "template-props": { instance: _ctx.instance, emit: _ctx.emit, t: _ctx.t }
                          }, null, 8, ["template", "template-props"]))
                        : (_openBlock(), _createElementBlock("button", _hoisted_9, _toDisplayString(_ctx.t('Actions')), 1))
                    ]),
                    dropdown: _withCtx(({ close }) => [
                      _createElementVNode("ul", _hoisted_10, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.productListMenuItems(), (item, index) => {
                          return (_openBlock(), _createElementBlock("li", { key: index }, [
                            (item.text === '-')
                              ? (_openBlock(), _createElementBlock("hr", _hoisted_11))
                              : (_ctx.isDisabled(item))
                                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                                    (item.itemTemplate)
                                      ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                          key: 0,
                                          template: item.itemTemplate,
                                          "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, t: _ctx.t }
                                        }, null, 8, ["template", "template-props"]))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createTextVNode(_toDisplayString(item.text), 1)
                                        ], 64))
                                  ]))
                                : (_openBlock(), _createElementBlock("a", {
                                    key: 2,
                                    class: "dropdown-item",
                                    href: "",
                                    onClick: _withModifiers(($event: any) => {
                      _ctx.onProductListMenuAction(item);
                      close();
                    }, ["prevent"])
                                  }, [
                                    (item.itemTemplate)
                                      ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                                          key: 0,
                                          template: item.itemTemplate,
                                          "template-props": { instance: _ctx.instance, item, emit: _ctx.emit, t: _ctx.t }
                                        }, null, 8, ["template", "template-props"]))
                                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                          _createTextVNode(_toDisplayString(item.text), 1)
                                        ], 64))
                                  ], 8, _hoisted_13))
                          ]))
                        }), 128))
                      ])
                    ]),
                    _: 1
                  })
                ], 2))
              : _createCommentVNode("", true)
          ])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}