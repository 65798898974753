import _mergeWith from 'lodash/mergeWith';
import _isEqualWith from 'lodash/isEqualWith';
import { reactive } from 'vue';
import { Instance } from '../Instance';
import { IRouteData } from './IRouteData';

import _cloneDeep from 'lodash/cloneDeep';
import { defaultRoute } from '../configuration/DefaultRoute';

export class Router {
  routeData = reactive<IRouteData>(_cloneDeep(defaultRoute));
  history: IRouteData[] = [];

  constructor(private instance: Instance) {}

  /**
   * @param routeSettings
   * @param passive flag that disables the API call. Useful with changing the display modes.
   */
  public async setRoute(routeSettings?: IRouteData, forceReload?: boolean): Promise<void> {
    // Fill in routeData if this is initial call

    if (!Object.keys(this.routeData).length) {
      _mergeWith(this.routeData, routeSettings || defaultRoute);
    }

    const newRoute = _cloneDeep(this.routeData);

    if (routeSettings) {
      // When route is set from outside pagination may be passed as string
      if (routeSettings.products?.pagination) {
        routeSettings.products.pagination.page = parseInt(
          routeSettings.products.pagination.page as never,
          10,
        );
        routeSettings.products.pagination.pageSize = parseInt(
          routeSettings.products.pagination.pageSize as never,
          10,
        );

        if (isNaN(routeSettings.products.pagination.page)) {
          routeSettings.products.pagination.page = this.routeData.products.pagination.page;
        }

        if (isNaN(routeSettings.products.pagination.pageSize)) {
          routeSettings.products.pagination.pageSize = this.routeData.products.pagination.pageSize;
        }
      }

      if (routeSettings.catalogs?.pagination) {
        routeSettings.catalogs.pagination.page = parseInt(
          routeSettings.catalogs.pagination.page as never,
          10,
        );
        routeSettings.catalogs.pagination.pageSize = parseInt(
          routeSettings.catalogs.pagination.pageSize as never,
          10,
        );

        if (isNaN(routeSettings.catalogs.pagination.page)) {
          routeSettings.catalogs.pagination.page = this.routeData.catalogs.pagination.page;
        }

        if (isNaN(routeSettings.catalogs.pagination.pageSize)) {
          routeSettings.catalogs.pagination.pageSize = this.routeData.catalogs.pagination.pageSize;
        }
      }

      _mergeWith(newRoute, routeSettings, (objValue, srcValue) => {
        // Allow to override existing arrays with empty ones instead of merging them.
        // Useful with eg.: filters.
        if (Array.isArray(srcValue)) {
          return srcValue;
        }

        // To clear value we need to return null
        if (srcValue === undefined) {
          return null;
        }
      });
    }

    // if only displayMode changes, no need to reload results
    let onlyDisplayModeChanged = false;
    if (this.history.length > 1 && newRoute.view == 'products') {
      const lastRoute = this.history[this.history.length - 1];
      onlyDisplayModeChanged = _isEqualWith(lastRoute, newRoute, (v1, v2, key) => {
        if (key == 'displayMode') {
          return true;
        }
        return undefined;
      });
    }
    if (onlyDisplayModeChanged && !forceReload) {
      this.commitRoute(newRoute);
      return;
    }

    let onlyPaginationChanged = false;

    if (this.history.length > 1 && newRoute.view == 'products') {
      const lastRoute = this.history[this.history.length - 1];
      onlyPaginationChanged = _isEqualWith(lastRoute.products, newRoute.products, (v1, v2, key) => {
        if (key == 'page' || key == 'pageSize') {
          return true;
        }
        return undefined;
      });
    }

    if (this.instance.store.data.isInitialized && this.instance.httpService) {
      await this.instance.httpService.load(newRoute, onlyPaginationChanged);
      this.commitRoute(newRoute);
    } else {
      this.commitRoute(newRoute);
    }
  }

  private commitRoute(routeData: IRouteData) {
    this.history.push({ ...routeData });
    _mergeWith(this.routeData, routeData, (objValue, srcValue) => {
      // Allow to override existing arrays with empty ones instead of merging them.
      // Useful with eg.: filters.
      if (Array.isArray(srcValue)) {
        return srcValue;
      }

      // To clear value we need to return null
      if (srcValue === undefined) {
        return null;
      }
    });

    this.instance.eventBus.emit('route-changed', _cloneDeep(this.routeData));
  }

  public async back(steps = 1): Promise<void> {
    const newIndex = this.history.length - steps - 1;
    const newRoute = this.history[newIndex];

    if (newRoute) {
      this.history.splice(newIndex);
    }

    this.setRoute(newRoute ?? defaultRoute);
  }
}
