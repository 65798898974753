import { TranslationItem } from '@/common/services/swagger/index.defs';
import { reactive } from 'vue';
import { Instance } from '../Instance';

export type TranslationsStored = Record<string, Record<string, string>>;

export class Translations {
  public translations = reactive<TranslationsStored>({});

  constructor(public instance: Instance) {}

  public async load(): Promise<TranslationsStored> {
    const fetchedTranslations = await this.instance.httpService?.loadTranslations();

    fetchedTranslations?.forEach(({ component, key, value }: TranslationItem) => {
      if (component && key && value) {
        if (!this.translations[component]) {
          this.translations[component] = {};
        }
        this.translations[component][key] = value;
      }
    });

    this.instance.store.data.translationsReady = true;

    return this.translations;
  }
}
