
import { Ref, computed, defineComponent, ref, watch } from 'vue';

import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DisplayMode } from '@/catalogs/api/runtime/IRouteData';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import {
  ProductsDisplayModeCommand,
  ProductsPageSizeCommand,
  ProductsSortByCommand,
} from '@/catalogs/api/runtime/CommandExecutor';
import { CatalogItem, CatalogProductSortFieldEnum } from '@/common/services/swagger/index.defs';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Icon from '@/common/components/Icon.vue';
import Clickable from '@/common/components/Clickable.vue';
import PaginationAmount from '@/common/components/PaginationAmount.vue';
import Btn from '@/common/components/Btn.vue';
import Dropdown from '@/common/components/Dropdown.vue';
import Arrow from '@/common/components/Arrow.vue';
import useCatalogActions from '@/catalogs/composables/useCatalogActions';
import { Instance } from '@/catalogs/api/Instance';
import DropdownPopper from '@/common/components/DropdownPopper.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { IMenuItem } from '@/catalogs/api/configuration/components/IExportMenuOptions';

export default defineComponent({
  name: ComponentName.productsSearchToolbar,

  components: {
    InjectStyles,
    PaginationAmount,
    Icon,
    Clickable,
    Btn,
    Dropdown,
    Arrow,
    DropdownPopper,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props, { emit }) {
    const root = ref<HTMLElement>();
    const exportMenuOpen = ref(false);
    const currentCatalog = ref<CatalogItem | undefined>(undefined);
    const sortByItems = {
      LastImport: CatalogProductSortFieldEnum.LastImport,
      Product: CatalogProductSortFieldEnum.Product,
      LastModified: CatalogProductSortFieldEnum.LastModified,
    };

    const {
      componentName,
      isReady,
      isWebComponent,
      instance,
      store,
      routeData,
      t,
      widerThan,
      narrowerThan,
    } = setupComponent(root, props.instanceId);

    const { onProductListMenuAction } = useCatalogActions(instance as Ref<Instance>, t);

    const displayModes = [
      {
        type: DisplayMode.table,
        tName: 'DisplayModeTable',
        icon: 'table',
        relatedComponent: ComponentName.tableView,
      },
      {
        type: DisplayMode.list,
        tName: 'DisplayModeList',
        icon: 'list',
        relatedComponent: ComponentName.listView,
      },
      {
        type: DisplayMode.grid,
        tName: 'DisplayModeGrid',
        icon: 'grid',
        relatedComponent: ComponentName.gridView,
      },
    ];

    const allowedDisplayModes = computed(
      () => store.value?.options.components?.productsSearchToolbar?.displayModes,
    );

    const visibleDisplayModes = computed(() => {
      if (!allowedDisplayModes.value) {
        return displayModes;
      }

      const filtered = displayModes.filter(
        ({ type, relatedComponent }) =>
          allowedDisplayModes.value?.includes(type) &&
          instance.value?.registeredComponents.has(relatedComponent),
      );

      const ordered: any[] = [];

      allowedDisplayModes.value.forEach((type) => {
        var item = filtered.find((a) => a.type == type);
        if (item) {
          ordered.push(item);
        }
      });

      return ordered;
    });

    const totalCount = computed(() => store.value?.data.products?.totalCount);
    const pagination = computed(() => routeData.value?.products.pagination);

    const sortBy = computed({
      get: () => routeData.value?.products.sortField,
      set: (sortField: any) => instance.value?.execute(new ProductsSortByCommand({ sortField })),
    });

    const pageSize = computed({
      get: () => pagination.value?.pageSize ?? 24,
      set: (pageSize: number) => instance.value?.execute(new ProductsPageSizeCommand({ pageSize })),
    });

    const pageSizeItems = computed(
      () => store.value?.options.components?.productsSearchToolbar?.pageSizes,
    );
    const toolbarVisible = computed(
      () => store.value?.options.components?.productsSearchToolbar?.visible,
    );

    const changeDisplayMode = (mode: DisplayMode) => {
      instance.value?.execute(new ProductsDisplayModeCommand({ mode }));
    };

    const isModeActive = ({ type }) => type === routeData.value?.products.displayMode;

    const productListMenu = computed(
      () => store.value?.options.components?.actions?.productListMenu,
    );

    const productListMenuItems = (): IMenuItem<void>[] =>
      (productListMenu.value?.items || []).filter((item: IMenuItem<void>) => {
        if (typeof item.visible === 'function') {
          return item.visible();
        }
        return item.visible;
      });

    const isDisabled = (item: IMenuItem<void>) =>
      typeof item.disabled === 'function' ? item.disabled() : item.disabled;

    const actionsEnabled = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.catalogsActionModals) &&
        productListMenu.value?.visible,
    );

    const setCurrentCatalog = () => {
      if (!routeData?.value?.products.catalogId) {
        currentCatalog.value = undefined;
        return;
      }
      currentCatalog.value =
        store.value?.data.catalogs?.items?.find(
          (catalog: CatalogItem) => catalog.id === routeData?.value?.products.catalogId,
        ) || undefined;
    };

    watch(
      () => routeData?.value?.products.catalogId,
      () => setCurrentCatalog(),
    );

    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      routeData,
      toolbarVisible,
      displayModes,
      visibleDisplayModes,
      totalCount,
      pagination,
      sortByItems,
      sortBy,
      pageSizeItems,
      pageSize,
      exportMenuOpen,
      productListMenu,
      actionsEnabled,
      instance,
      currentCatalog,
      t,
      emit,
      widerThan,
      isDisabled,
      isModeActive,
      narrowerThan,
      changeDisplayMode,
      productListMenuItems,
      onProductListMenuAction,
    };
  },
});
