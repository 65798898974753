import {
  JobAction,
  CatalogAppSettings,
  ClientInfo,
  Catalog,
  CatalogTypeEnum,
  Owner,
  OwnerTypeEnum,
  ActionResult,
  ActionInfo,
  ExportActionResult,
  JobSearchQuery,
  JobSortSettings,
  JobSortFieldEnum,
  SortDirectionEnum,
  JobSearchResult,
  JobItem,
  JobTypeEnum,
  JobStatusEnum,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class CatalogsJobsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Cancel job
   */
  static cancel(
    params: {
      /** requestBody */
      body?: JobAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Jobs/Cancel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Download Excel file
   */
  static download(
    params: {
      /** Guid of requested file */
      guid?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Jobs/Download';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { guid: params['guid'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Export job into Excel file
   */
  static export(
    params: {
      /** requestBody */
      body?: JobAction;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExportActionResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Jobs/Export';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Search jobs
   */
  static search(
    params: {
      /** requestBody */
      body?: JobSearchQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<JobSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Catalogs/Jobs/Search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
