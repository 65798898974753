
import { computed, defineComponent, PropType, ref } from 'vue';
import _debounce from 'lodash/debounce';
import { FilterItem, FilterValue } from '@/common/services/swagger/index.defs';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';

import Modal from '@/common/components/Modal.vue';
import Badge from '@/common/components/Badge.vue';
import Btn from '@/common/components/Btn.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import Spinner from '@/common/components/Spinner.vue';
import FormInputClearable from '@/common/components/FormInputClearable.vue';
import ScrollableList from '@/common/components/ScrollableList.vue';
import Message from '@/common/components/Message.vue';
import FiltersList from './FiltersList.vue';
import Arrow from '@/common/components/Arrow.vue';
import { getPathFromEvent } from '@/common/helpers/dom';

export default defineComponent({
  components: {
    Modal,
    Badge,
    Spinner,
    Btn,
    LinkSecondary,
    FormInputClearable,
    ScrollableList,
    Message,
    FiltersList,
    Arrow,
  },

  props: {
    show: Boolean,
    title: { type: String, required: true },
    isMobile: Boolean,
    t: { type: Function as PropType<TranslateFunc>, required: true },
    filtersAll: Array as PropType<FilterItem[]>,
    filtersPromoted: Array as PropType<FilterItem[]>,
    filtersAdditional: Array as PropType<FilterItem[]>,
    filtersFetched: Map as PropType<Map<string, FilterValue[]>>,
    filtersModel: Object,
    filteredResultCount: Number,
    filteredResultCountLoading: Boolean,
    fetchFilterValues: { type: Function, required: true },
  },

  emits: ['update:show', 'value-change', 'apply-filters', 'clear'],

  setup(props, { emit }) {
    const filterValuesLoading = ref(false);
    const currentFilterAttrCode = ref<string | null>(null);
    const areFiltersDirty = ref(false);
    const filterNameSearchPhrase = ref('');
    const filterValueSearchPhrase = ref('');
    const modalFooter = ref<HTMLElement>();

    const currentFilterData = computed(() =>
      props.filtersAll?.find(({ attributeCode }) => attributeCode === currentFilterAttrCode.value),
    );

    const filtersAdditionalSearched = computed(() => {
      if (filterNameSearchPhrase.value.length) {
        const phrase = filterNameSearchPhrase.value.toLowerCase();
        return props.filtersAdditional?.filter(({ attributeName }) =>
          attributeName.toLowerCase().includes(phrase),
        );
      } else {
        return props.filtersAdditional;
      }
    });

    const filtersPromotedSearched = computed(() => {
      if (props.filtersPromoted?.length && filterNameSearchPhrase.value.length) {
        const phrase = filterNameSearchPhrase.value.toLowerCase();
        return props.filtersPromoted?.filter(({ attributeName }) =>
          attributeName.toLowerCase().includes(phrase),
        );
      } else {
        return props.filtersPromoted;
      }
    });

    const currentFilterValues = computed(() => {
      if (props.filtersFetched && currentFilterAttrCode.value) {
        return props.filtersFetched.get(currentFilterAttrCode.value);
      }
      return null;
    });

    const currentFilterValuesSearched = computed(() => {
      if (currentFilterValues.value) {
        if (filterValueSearchPhrase.value.length) {
          const phrase = filterValueSearchPhrase.value.toLowerCase();
          return currentFilterValues.value?.filter(({ name }) =>
            name.toLowerCase().includes(phrase),
          );
        } else {
          return currentFilterValues.value;
        }
      } else {
        return null;
      }
    });

    const openFilter = async ({ attributeCode }: FilterItem) => {
      if (!attributeCode) return;
      filterValueSearchPhrase.value = '';
      if (!props.filtersFetched?.has(attributeCode)) {
        filterValuesLoading.value = true;
        await props.fetchFilterValues([attributeCode]);
        filterValuesLoading.value = false;
      }

      currentFilterAttrCode.value = attributeCode;
    };

    const onValueChange = (event: InputEvent, attributeCode: string, filterValueName: string) => {
      areFiltersDirty.value = true;

      emit('value-change', {
        attributeCode,
        filterValueName,
        value: getPathFromEvent<InputEvent, HTMLInputElement>(event)[0].checked,
      });
    };

    const applyFilterNameSearch = _debounce((val) => (filterNameSearchPhrase.value = val), 200);

    const resetFilterNameSearch = () => (filterNameSearchPhrase.value = '');

    const applyFilterValueSearch = _debounce((val) => (filterValueSearchPhrase.value = val), 200);

    const resetFilterValueSearch = () => (filterValueSearchPhrase.value = '');

    const resetCurrentFilterAttrCode = () => {
      // Delay the action to prevent modal window from closing itself
      setTimeout(() => {
        currentFilterAttrCode.value = null;
      }, 50);
    };

    const clear = () => {
      currentFilterAttrCode.value = null;
      emit('clear');
    };

    const filtersSectionHeight = ref('300px');
    const filtersSectionRightHeight = ref('300px');

    const modalResize = (e) => {
      let bodyHeight = e.height - 110;

      if (props.filtersPromoted?.length) {
        filtersSectionHeight.value = bodyHeight / 2 - 20 + 'px';
      } else {
        filtersSectionHeight.value = bodyHeight + 'px';
      }

      filtersSectionRightHeight.value = bodyHeight + 'px';
    };

    return {
      areFiltersDirty,
      currentFilterData,
      currentFilterValues,
      filterValuesLoading,
      filtersSectionHeight,
      filtersSectionRightHeight,
      applyFilterNameSearch,
      currentFilterAttrCode,
      applyFilterValueSearch,
      filtersPromotedSearched,
      filterValueSearchPhrase,
      filtersAdditionalSearched,
      currentFilterValuesSearched,
      modalFooter,
      clear,
      openFilter,
      onValueChange,
      resetFilterNameSearch,
      resetFilterValueSearch,
      resetCurrentFilterAttrCode,
      modalResize,
    };
  },
});
