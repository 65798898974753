
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import InteractiveGuides from '@/common/components/InteractiveGuides.vue';
import { DataType } from '@/products/api/configuration/application/DataType';

export default defineComponent({
  name: ComponentName.detailsInteractiveGuides,

  components: {
    InjectStyles,
    InteractiveGuides,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.InteractiveGuides]
    );
    const interactiveGuides = computed(() => store.value?.data.interactiveGuides?.items);
    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      interactiveGuides,
      t,
    };
  },
});
