
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import { attrValues } from '@/common/helpers/productAttributes';

export default defineComponent({
  name: ComponentName.detailsIdentifiers,

  components: {
    InjectStyles,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { componentName, isReady, isWebComponent, store, t } = setupComponent(
      root,
      props.instanceId,
      []
    );

    const allIdentifiers = computed(() => {
      if (
        store.value?.data.productDetail?.item &&
        store.value.data.productDetail.item.attributes['#AllIdentifiers']
      ) {
        return attrValues(store.value?.data.productDetail.item, '#AllIdentifiers');
      }
      return [];
    });
    return {
      root,
      componentName,
      isReady,
      isWebComponent,
      store,
      allIdentifiers,
      t,
    };
  },
});
