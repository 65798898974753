
import { defineComponent, ref, computed } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';

import InjectStyles from '@/common/components/InjectStyles.vue';
import FavoriteCategories from '@/common/components/FavoriteCategories.vue';

export default defineComponent({
  name: ComponentName.searchFavoriteCategories,

  components: {
    InjectStyles,
    FavoriteCategories,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { isReady, isWebComponent, componentName, store, t, instance } = setupComponent(
      root,
      props.instanceId,
      [],
    );

    const visible = computed(() => store.value?.options.components?.favoriteCategories?.visible);

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      store,
      instance,
      t,
      visible,
    };
  },
});
