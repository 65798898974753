
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '../api/configuration/components/ComponentName';
import { setupComponent } from '../composables/setupComponent';
import CategoriesList from '@/common/components/CategoriesList.vue';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Btn from '@/common/components/Btn.vue';
import Modal from '@/common/components/Modal.vue';

import { DataType } from './../api/configuration/application/DataType';
import AliasFilters from './aliases/AliasFilters.vue';
import { ClassificationItem, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { ClassificationCommand } from '../api/runtime/CommandExecutor';

export default defineComponent({
  name: ComponentName.mobileNav,

  components: {
    InjectStyles,
    Btn,
    Modal,
    AliasFilters,
    CategoriesList,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const aliasFiltersModalOpen = ref(false);
    const categoriesModalOpen = ref(false);
    const partCategoriesModalOpen = ref(false);

    const { componentName, isWebComponent, isReady, instance, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.Breadcrumbs],
    );

    const categoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchProductCategories) &&
        store.value?.options.components?.searchProductCategories?.visible,
    );

    const partCategoriesShown = computed(
      () =>
        instance.value?.registeredComponents.has(ComponentName.searchPartCategories) &&
        store.value?.options.components?.searchPartCategories?.visible,
    );

    const categories = computed(() => store.value?.data.productClassifications?.items ?? []);
    const partCategories = computed(() => store.value?.data.partClassifications?.items ?? []);
    const columnsNumber = computed(() => {
      const itemsNumber = [categoriesShown.value, partCategoriesShown.value, true].filter(
        Boolean,
      ).length;

      return itemsNumber > 3 ? 3 : itemsNumber; // 3 or less columns
    });

    const setCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        categoriesModalOpen.value = false;
        try {
          await instance.value.execute(
            new ClassificationCommand({ cid, clearSearch: false, treeType: TreeTypeEnum.Products }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const setPartCategory = async ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        partCategoriesModalOpen.value = false;
        try {
          await instance.value.execute(
            new ClassificationCommand({ cid, clearSearch: false, treeType: TreeTypeEnum.Parts }),
          );
        } catch (error) {
          // Ignore Error
        }
      }
    };

    return {
      root,
      componentName,
      isWebComponent,
      categoriesShown,
      partCategoriesShown,
      categories,
      partCategories,
      isReady,
      store,
      columnsNumber,
      instance,
      aliasFiltersModalOpen,
      categoriesModalOpen,
      partCategoriesModalOpen,
      t,
      setCategory,
      setPartCategory,
    };
  },
});
