import {
  ImageByClassificationCidsQuery,
  ImageClassificationSearchSettings,
  AppSettings,
  TreeTypeEnum,
  AliasSettings,
  Customer,
  ApplicationFilters,
  Criteria,
  AppSpecificSettings,
  ClientInfo,
  ImageSearchResult,
  ImageItem,
  ImageByImageIdsQuery,
  ImageSearchSettings,
  ImageByProductIdsQuery,
  ImageProductSearchSettings,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ImagesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Returns images by classification cids
   */
  static getByClassificationCids(
    params: {
      /** requestBody */
      body?: ImageByClassificationCidsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Images/GetByClassificationCids';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Returns images by image ids
   */
  static getByImageIds(
    params: {
      /** requestBody */
      body?: ImageByImageIdsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Images/GetByImageIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Returns product images
   */
  static getByProductIds(
    params: {
      /** requestBody */
      body?: ImageByProductIdsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ImageSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Images/GetByProductIds';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
