
import { defineComponent, PropType } from 'vue';
import { TranslateFunc } from '@/catalogs/composables/setupComponent';
import { CatalogDetail, QuantityItem } from '@/common/services/swagger/index.defs';

import ItemConfiguredOption from './ItemConfiguredOption.vue';

export default defineComponent({
  components: { ItemConfiguredOption },
  props: {
    t: { type: Function as PropType<TranslateFunc>, required: true },
    instanceId: String,
    qty: { type: Object as PropType<QuantityItem>, required: true },
    catalog: { type: Object as PropType<CatalogDetail>, required: true },
  },
});
