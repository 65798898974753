import {
  ClassificationNodesQuery,
  ClassificationNodesSearchSettings,
  AppSettings,
  TreeTypeEnum,
  AliasSettings,
  Customer,
  ApplicationFilters,
  Criteria,
  AppSpecificSettings,
  ClientInfo,
  ClassificationNodesSearchResult,
  ClassificationNode,
  ClassificationParentsQuery,
  ClassificationParentsSearchSettings,
  ClassificationQuery,
  ClassificationSearchSettings,
  ClassificationsSearchResult,
  ClassificationAdditionalInfo,
  ClassificationAdditionalInfoTypeEnum,
  ClassificationItem,
  Image,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class ClassificationsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   * Returns nodes information
   */
  static nodes(
    params: {
      /** requestBody */
      body?: ClassificationNodesQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationNodesSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Nodes';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Returns parents (path up to ROOT) of the selected cid
   */
  static parents(
    params: {
      /** requestBody */
      body?: ClassificationParentsQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationNodesSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Parents';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Returns classifications tree
   */
  static tree(
    params: {
      /** requestBody */
      body?: ClassificationQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ClassificationsSearchResult> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/v1/Classifications/Tree';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
