import CatalogsNotifications from './CatalogsNotifications.vue';
import CatalogsLoadingIndicator from './CatalogsLoadingIndicator.vue';
import CatalogsProductSwitch from './common/CatalogProductSwitch.vue';
import CatalogsLayout from './CatalogsLayout.vue';
import CatalogsInstance from '@/catalogs/components/CatalogsInstance.vue';
import CatalogsSearchTable from '@/catalogs/components/catalogs/CatalogsSearchTable.vue';
import CatalogsSearchForm from '@/catalogs/components/catalogs/CatalogsSearchForm.vue';
import CatalogsSearchToolbar from '@/catalogs/components/catalogs/CatalogsSearchToolbar.vue';
import CatalogsSearchPagination from '@/catalogs/components/catalogs/CatalogsSearchPagination.vue';
import ProductsBreadcrumbs from '@/catalogs/components/products/ProductsBreadcrumbs.vue';
import ProductsSearchProductCategories from '@/catalogs/components/products/ProductsSearchProductCategories.vue';
import ProductsSearchPartCategories from '@/catalogs/components/products/ProductsSearchPartCategories.vue';
import ProductsSearchFilters from '@/catalogs/components/products/ProductsSearchFilters.vue';
import ProductsSearchFiltersStatus from '@/catalogs/components/products/ProductsSearchFiltersStatus.vue';
import ProductsSearchForm from '@/catalogs/components/products/ProductsSearchForm.vue';
import ProductsPreviewView from '@/catalogs/components/preview/ProductsPreviewView.vue';
import ProductsSearchToolbar from '@/catalogs/components/products/ProductsSearchToolbar.vue';
import ProductsSearchTable from '@/catalogs/components/products/ProductsSearchTable.vue';
import ProductsTableView from '@/catalogs/components/views/ProductsTableView.vue';
import ProductsSearchList from '@/catalogs/components/products/ProductsSearchList.vue';
import ProductsListView from '@/catalogs/components/views/ProductsListView.vue';
import ProductsSearchGrid from '@/catalogs/components/products/ProductsSearchGrid.vue';
import ProductsSearchSelection from '@/catalogs/components/products/ProductsSearchSelection.vue';

import ProductsGridView from '@/catalogs/components/views/ProductsGridView.vue';
import ProductsSearchNoResults from '@/catalogs/components/products/ProductsSearchNoResults.vue';
import ProductsSearchPagination from '@/catalogs/components/products/ProductsSearchPagination.vue';
import CatalogActionModals from '@/catalogs/components/common/CatalogActionModals.vue';
import CatalogProductActionModals from '@/catalogs/components/common/CatalogProductActionModals.vue';
import CatalogsView from '@/catalogs/components/views/CatalogsView.vue';
import ProductsView from '@/catalogs/components/views/ProductsView.vue';
import AliasesView from '@/catalogs/components/views/AliasesView.vue';
import CatalogProductsMobileNav from '@/catalogs/components/CatalogProductsMobileNav.vue';

import ProductsSearchAliasFilters from '@/catalogs/components/products/ProductsSearchAliasFilters.vue';
import AliasesSearchTable from '@/catalogs/components/aliases/AliasesSearchTable.vue';

export default [
  CatalogsNotifications,
  CatalogsLoadingIndicator,
  CatalogsProductSwitch,
  CatalogsInstance,
  CatalogsSearchTable,
  CatalogsLayout,
  CatalogsSearchPagination,
  CatalogsSearchForm,
  CatalogsSearchToolbar,
  ProductsBreadcrumbs,
  ProductsSearchProductCategories,
  ProductsSearchPartCategories,
  ProductsSearchFilters,
  ProductsSearchFiltersStatus,
  ProductsSearchForm,
  ProductsPreviewView,
  ProductsSearchToolbar,
  ProductsSearchTable,
  ProductsTableView,
  ProductsSearchList,
  ProductsListView,
  ProductsSearchGrid,
  ProductsSearchSelection,
  ProductsGridView,
  ProductsSearchNoResults,
  ProductsSearchPagination,
  CatalogActionModals,
  CatalogProductActionModals,
  CatalogsView,
  ProductsView,
  AliasesView,
  CatalogProductsMobileNav,
  ProductsSearchAliasFilters,
  AliasesSearchTable,
];
