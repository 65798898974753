import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createSlots as _createSlots, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = {
  key: 0,
  class: "d-flex mb-3"
}
const _hoisted_3 = {
  key: 1,
  class: "list-unstyled mb-0"
}
const _hoisted_4 = { class: "d-block ms-2 text-body text-break pis-label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "form-check pe-1" }
const _hoisted_7 = { class: "form-check-label d-flex align-items-start py-1 ms-1" }
const _hoisted_8 = ["onUpdate:modelValue", "name"]
const _hoisted_9 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_LinkSecondary = _resolveComponent("LinkSecondary")!
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_Arrow = _resolveComponent("Arrow")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_Clickable = _resolveComponent("Clickable")!
  const _component_ScrollableList = _resolveComponent("ScrollableList")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Card = _resolveComponent("Card")!
  const _component_FiltersNavigator = _resolveComponent("FiltersNavigator")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Card, {
            class: "mb-4",
            "sticky-footer": "",
            icon: _ctx.componentOptions.icon && 'filter',
            header: _ctx.t('Filters', true),
            "use-markup": _ctx.componentOptions.card
          }, _createSlots({
            default: _withCtx(() => [
              (
            _ctx.filtersToDisplay?.length &&
            (_ctx.componentOptions.expandAllVisible || _ctx.componentOptions.collapseAllVisible)
          )
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.componentOptions.expandAllVisible)
                      ? (_openBlock(), _createBlock(_component_LinkSecondary, {
                          key: 0,
                          icon: "expand-all",
                          class: "ms-1 me-4 ext-expand-all-filters",
                          onClick: _withModifiers(_ctx.expandAll, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('ExpandAllFilters')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.componentOptions.collapseAllVisible)
                      ? (_openBlock(), _createBlock(_component_LinkSecondary, {
                          key: 1,
                          icon: "collapse-all",
                          class: "ext-collapse-all-filters",
                          onClick: _withModifiers(_ctx.collapseAll, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('CollapseAllFilters')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true),
              (_ctx.filtersToDisplay?.length)
                ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filtersToDisplay, (filter) => {
                      return (_openBlock(), _createElementBlock("li", {
                        key: filter.attributeCode,
                        class: "pb-2"
                      }, [
                        _createVNode(_component_Clickable, {
                          class: "py-1 d-flex align-items-start text-decoration-none lh-sm",
                          "aria-expanded": _ctx.isFilterOpen(filter),
                          onClick: ($event: any) => (_ctx.toggleFilter(filter))
                        }, {
                          default: _withCtx(() => [
                            (_ctx.isFilterLoading(filter))
                              ? (_openBlock(), _createBlock(_component_Spinner, {
                                  key: 0,
                                  sm: ""
                                }))
                              : (_openBlock(), _createBlock(_component_Arrow, {
                                  key: 1,
                                  dir: _ctx.isFilterOpen(filter) ? 'up' : 'down'
                                }, null, 8, ["dir"])),
                            _createElementVNode("span", _hoisted_4, _toDisplayString(filter.attributeName), 1),
                            _createVNode(_component_Badge, {
                              class: "ms-auto",
                              textContent: _toDisplayString(filter.hitCount)
                            }, null, 8, ["textContent"])
                          ]),
                          _: 2
                        }, 1032, ["aria-expanded", "onClick"]),
                        (_ctx.isFilterOpen(filter))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                              (_ctx.filtersFetched.get(filter.attributeCode)?.length)
                                ? (_openBlock(), _createBlock(_component_ScrollableList, {
                                    key: 0,
                                    class: "list-unstyled my-1 ms-2",
                                    height: "30vh",
                                    items: _ctx.filtersFetched.get(filter.attributeCode) || []
                                  }, {
                                    item: _withCtx(({ item }) => [
                                      _createElementVNode("div", _hoisted_6, [
                                        _createElementVNode("label", _hoisted_7, [
                                          _withDirectives(_createElementVNode("input", {
                                            "onUpdate:modelValue": ($event: any) => ((_ctx.filtersModel[filter.attributeCode][`${item.name}:${item.code}`]) = $event),
                                            type: "checkbox",
                                            class: "form-check-input mt-1 me-2 flex-shrink-0",
                                            name: item.name,
                                            onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFilterValueChange && _ctx.onFilterValueChange(...args)))
                                          }, null, 40, _hoisted_8), [
                                            [_vModelCheckbox, _ctx.filtersModel[filter.attributeCode][`${item.name}:${item.code}`]]
                                          ]),
                                          _createElementVNode("span", {
                                            textContent: _toDisplayString(item.name),
                                            class: "text-break user-select-none"
                                          }, null, 8, _hoisted_9),
                                          _createVNode(_component_Badge, {
                                            class: "ms-auto",
                                            textContent: _toDisplayString(item.hitCount)
                                          }, null, 8, ["textContent"])
                                        ])
                                      ])
                                    ]),
                                    _: 2
                                  }, 1032, ["items"]))
                                : (_openBlock(), _createBlock(_component_Message, { key: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t('NoFilterValues')), 1)
                                    ]),
                                    _: 1
                                  }))
                            ]))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : (_openBlock(), _createBlock(_component_Message, { key: 2 }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t('NoFilters')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _: 2
          }, [
            (_ctx.areFiltersApplied || _ctx.filtersAll?.length)
              ? {
                  name: "footer",
                  fn: _withCtx(() => [
                    (_ctx.filteredResultCount !== null && _ctx.areFiltersDirty)
                      ? (_openBlock(), _createBlock(_component_Btn, {
                          key: 0,
                          class: "w-100 mb-2 justify-content-between",
                          disabled: _ctx.areFiltersDirty !== true || _ctx.filteredResultCount === 0,
                          "is-loading": _ctx.filteredResultCountLoading,
                          onClick: _withModifiers(_ctx.applyModelFilters, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            (_ctx.filteredResultCount === null)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createTextVNode(_toDisplayString(_ctx.t('ShowResults', true)), 1)
                                ], 64))
                              : (_ctx.filteredResultCount === 0)
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                    _createTextVNode(_toDisplayString(_ctx.t('NoFilteringResultsFound')), 1)
                                  ], 64))
                                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                    _createTextVNode(_toDisplayString(_ctx.t('ShowNResults', true, [`${_ctx.filteredResultCount || '0'}`])), 1)
                                  ], 64))
                          ]),
                          _: 1
                        }, 8, ["disabled", "is-loading", "onClick"]))
                      : _createCommentVNode("", true),
                    (_ctx.areFiltersApplied)
                      ? (_openBlock(), _createBlock(_component_LinkSecondary, {
                          key: 1,
                          icon: "cancel",
                          class: "mt-2",
                          onClick: _withModifiers(_ctx.clearAndCloseFilters, ["prevent"])
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t('ClearFilters', true)), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"]))
                      : _createCommentVNode("", true),
                    _createVNode(_component_LinkSecondary, {
                      icon: "pop-out",
                      class: "mt-2",
                      onClick: _withModifiers(_ctx.showAllFilters, ["prevent"])
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.t('ShowAllFilters')) + " (" + _toDisplayString(_ctx.filtersAll.length) + ") ", 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ])
                }
              : undefined
          ]), 1032, ["icon", "header", "use-markup"]),
          _createVNode(_component_FiltersNavigator, {
            show: _ctx.allFiltersModalShown,
            title: _ctx.t('AllFiltersModalTitle'),
            t: _ctx.t,
            "filters-all": _ctx.filtersAll,
            "filters-promoted": _ctx.filtersPromoted,
            "filters-additional": _ctx.filtersAdditional,
            "filters-fetched": _ctx.filtersFetched,
            "filters-model": _ctx.filtersModel,
            "fetch-filter-values": _ctx.fetchFilterValues,
            "filtered-result-count": _ctx.filteredResultCount,
            "filtered-result-count-loading": _ctx.filteredResultCountLoading,
            onValueChange: _ctx.onModalFilterValueChange,
            onApplyFilters: _ctx.onModalApplyFilters,
            onClear: _ctx.resetFiltersApplied
          }, null, 8, ["show", "title", "t", "filters-all", "filters-promoted", "filters-additional", "filters-fetched", "filters-model", "fetch-filter-values", "filtered-result-count", "filtered-result-count-loading", "onValueChange", "onApplyFilters", "onClear"])
        ], 64))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}