
import { computed, defineComponent, nextTick, onBeforeUnmount, ref, watch } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import InteractiveText from '@/common/components/InteractiveText.vue';
import InjectStyles from '@/common/components/InjectStyles.vue';
import PisLink from '@/common/components/PisLink.vue';
import {
  AttributeTypeEnum,
  CompareProductDetail,
  ProductAttribute,
  ProductItem,
} from '@/common/services/swagger/index.defs';
import Icon from '@/common/components/Icon.vue';
import Tooltip from '@/common/components/Tooltip.vue';
import { ICompareOptions } from '@/products/api/configuration/components/ICompareOptions';
import Image from '@/common/components/Image.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
import { attrValue, attrValues, additionalInfo } from '@/common/helpers/productAttributes';
import {
  DetailsCommand,
  FilterCompareCommand,
  RemoveCompareItemCommand,
  ShowComparePrintViewCommand,
} from '@/products/api/runtime/CommandExecutor';
import PisReplacements from '@/common/components/PisReplacements.vue';
import CompareTableData from './CompareTableData.vue';
import NavigationBtns from '../common/NavigationBtns.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import { useIntersectionObserver } from '@vueuse/core';
import _debounce from 'lodash/debounce';
export default defineComponent({
  name: ComponentName.compare,

  components: {
    InjectStyles,
    InteractiveText,
    PisLink,
    Icon,
    Tooltip,
    Image,
    PisReplacements,
    VRuntimeTemplate,
    CompareTableData,
    NavigationBtns,
    LinkSecondary,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const topSection = ref<HTMLElement>();
    const bottomSection = ref<HTMLElement>();
    const differencesOnly = ref(false);
    const tableData = ref<{ item: ProductItem; attr: ProductAttribute; imageSrc: string }[]>([]);
    const tableDataVisible = ref(false);
    const showMore = ref(false);
    const imageVisible = ref(true);
    const visibilityTest = ref();

    const { instance, componentName, isReady, isWebComponent, store, routeData, t, emit } =
      setupComponent(root, props.instanceId);

    const topScrolled = () => {
      if (topSection.value && bottomSection.value) {
        var scroll = topSection.value.scrollLeft ?? 0;
        bottomSection.value.scrollLeft = scroll;
      }
    };

    const bottomScrolled = () => {
      if (topSection.value && bottomSection.value) {
        var scroll = bottomSection.value.scrollLeft ?? 0;
        topSection.value.scrollLeft = scroll;
      }
    };

    let isObserverPaused = true;

    const unpauseObserver = () => {
      isObserverPaused = false;
    };

    useIntersectionObserver(
      visibilityTest,
      _debounce(([{ isIntersecting }]) => {
        if (
          !isObserverPaused &&
          componentOptions.value.imageVisible &&
          componentOptions.value.imageHideOnScroll
        ) {
          imageVisible.value = isIntersecting;
          isObserverPaused = true;
        }
      }, 300),
    );

    const unwatch = watch(
      () => isReady.value,
      (val) => {
        if (val == true) {
          differencesOnly.value = routeData.value?.compare?.differencesOnly ?? false;
          setTimeout(() => {
            topSection.value?.addEventListener('scroll', topScrolled);
            bottomSection.value?.addEventListener('scroll', bottomScrolled);
            document.addEventListener('scroll', unpauseObserver);
          }, 500);

          unwatch();
        }
      },
    );

    watch(
      () => differencesOnly.value,
      (val) => {
        filterByDiff(val);
      },
    );

    watch(
      () => routeData.value?.compare?.differencesOnly,
      (val) => {
        if (val && val != differencesOnly.value) {
          differencesOnly.value = val;
        }
      },
    );

    onBeforeUnmount(() => {
      topSection.value?.removeEventListener('scroll', topScrolled);
      bottomSection.value?.removeEventListener('scroll', bottomScrolled);
      document.removeEventListener('scroll', unpauseObserver);
    });

    const componentOptions = computed(
      () => store.value?.options.components?.compare ?? ({} as ICompareOptions),
    );

    const items = computed(() => store.value?.data.compareResult?.items ?? []);
    const categories = computed(() => store.value?.data.compareResult?.classifications ?? []);
    const noItemsFound = computed(() => store.value?.data?.compareResult?.items?.length == 0);

    const groups = computed(() => {
      if (!items.value.length) {
        return [];
      }

      return items.value[0].attributeGroups ?? [];
    });

    const getAttributes = (item: CompareProductDetail, groupCode: string, attrCode: string) => {
      // return array here to avoid null checks in template

      var group = item.attributeGroups?.find((a) => a.code == groupCode);

      if (group) {
        return [group.attributes[attrCode]];
      }

      return [];
    };

    const details = async (product: ProductItem) => {
      if (product?.inactive) return;

      if (routeData.value && product) {
        try {
          await instance.value?.execute(new DetailsCommand(product));
        } catch (error) {
          // Ignore Error
        }
      }
    };

    const replacementsVisible = computed(
      () => store.value?.options.components?.common?.hideReplacements != true,
    );

    const filterByCid = async (cid: string) => {
      try {
        await instance.value?.execute(new FilterCompareCommand({ cid: cid }));
      } catch (error) {
        // Ignore Error
      }
    };

    const filterByDiff = async (diffOnly: boolean) => {
      try {
        await instance.value?.execute(new FilterCompareCommand({ diffOnly: diffOnly }));
      } catch (error) {
        // Ignore Error
      }
    };
    const removeItem = async (item: ProductItem) => {
      try {
        await instance.value?.execute(new RemoveCompareItemCommand({ item }));
      } catch (error) {
        // Ignore Error
      }
    };

    const selectedCid = computed(() => {
      if (routeData.value?.compare?.cids?.length) {
        return routeData.value.compare.cids[0];
      }
      return 'ROOT';
    });

    const compareTableData = (grCode: string, attrCode: string) => {
      var attrs: { item: ProductItem; attr: ProductAttribute; imageSrc: string }[] = [];

      items.value.forEach((item) => {
        if (item.item && item.attributeGroups) {
          const gr = item.attributeGroups?.find((a) => a.code == grCode);
          if (gr) {
            const attr = gr.attributes[attrCode];
            if (attr && attr.type == AttributeTypeEnum.Table) {
              const imageSrc = (item.item.images || [])[0]?.thumbnailUrl;
              attrs.push({ item: item.item, attr, imageSrc });
            }
          }
        }
      });

      tableData.value = attrs;
      tableDataVisible.value = false;
      nextTick(() => {
        tableDataVisible.value = true;
      });
    };

    const hideDocumentLinks = computed(
      () => store.value?.options.components?.common?.hideDocumentLinks == true,
    );

    const print = async () => {
      try {
        await instance.value?.execute(new ShowComparePrintViewCommand(root.value as any));
      } catch (error) {
        // Ignore Error
      }
    };
    return {
      root,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      instance,
      items,
      categories,
      groups,
      replacementsVisible,
      AttributeTypeEnum,
      topSection,
      bottomSection,
      selectedCid,
      differencesOnly,
      tableData,
      tableDataVisible,
      showMore,
      noItemsFound,
      hideDocumentLinks,
      imageVisible,
      visibilityTest,
      filterByCid,
      t,
      getAttributes,
      emit,
      attrValue,
      attrValues,
      additionalInfo,
      details,
      compareTableData,
      removeItem,
      print,
    };
  },
});
