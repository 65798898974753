export enum ComponentName {
  common = 'common',

  productSwitch = 'pis-catalogs-product-switch',

  searchProductCategories = 'pis-catalogs-search-product-categories',
  searchPartCategories = 'pis-catalogs-search-part-categories',
  searchFilters = 'pis-catalogs-search-filters',
  searchFiltersStatus = 'pis-catalogs-search-filters-status',
  searchForm = 'pis-catalogs-search-form',
  searchGrid = 'pis-catalogs-search-grid',
  searchHeader = 'pis-catalogs-search-header',
  searchList = 'pis-catalogs-search-list',

  searchNoResultsView = 'pis-catalogs-search-no-results-view',
  searchPagination = 'pis-catalogs-search-pagination',
  searchTable = 'pis-catalogs-search-table',
  searchTableToolbar = 'pis-catalogs-search-table-toolbar',
  searchToolbar = 'pis-catalogs-search-toolbar',
  searchView = 'pis-catalogs-search-view',

  productsSearchToolbar = 'pis-catalogs-search-products-toolbar',
  searchProductsTable = 'pis-catalogs-search-products-table',
  searchProductsPagination = 'pis-catalogs-search-products-pagination',
  productsSearchForm = 'pis-catalogs-search-products-form',
  searchProductsNoResults = 'pis-catalogs-search-products-no-results',
  searchSelection = 'pis-catalogs-products-search-selection',

  breadcrumbs = 'pis-catalogs-breadcrumbs',
  instance = 'pis-catalogs-instance',
  layout = 'pis-catalogs-layout',
  loadingIndicator = 'pis-catalogs-loading-indicator',
  preview = 'pis-catalogs-product-preview',
  mobileNav = 'pis-catalogs-products-mobile-nav',
  tableView = 'pis-catalogs-table-view',
  listView = 'pis-catalogs-list-view',
  gridView = 'pis-catalogs-grid-view',

  catalogSearchTableActions = 'pis-catalogs-search-table-actions',
  catalogsActionModals = 'pis-catalogs-actions-modals',
  catalogProductsActionModals = 'pis-catalog-products-actions-modals',
  notifications = 'pis-catalogs-notifications',

  catalogsSearchView = 'pis-catalogs-search-view',
  catalogsProductsSearchView = 'pis-catalogs-products-search-view',
  catalogsAliasesSearchView = 'pis-catalogs-aliases-search-view',

  searchAliasFilters = 'pis-catalogs-aliases-filter',
  aliasesTable = 'pis-catalogs-aliases-search-table',
  aliasActionModals = 'pis-catalogs-aliases-actions-modals',
  aliasesSearchToolbar = 'pis-catalogs-search-aliases-toolbar',
}
