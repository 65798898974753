
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import InjectStyles from '@/common/components/InjectStyles.vue';
import Card from '@/common/components/Card.vue';
import { ISearchAliasFiltersOptions } from '@/catalogs/api/configuration/components/ISearchAliasFiltersOptions';
import AliasFilters from '@/catalogs/components/aliases/AliasFilters.vue';

export default defineComponent({
  name: ComponentName.searchAliasFilters,

  components: {
    InjectStyles,
    Card,
    AliasFilters,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { isReady, isWebComponent, componentName, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.ProductClassifications],
    );

    const componentOptions = computed(
      () =>
        store.value?.options.components?.searchAliasFilters ?? ({} as ISearchAliasFiltersOptions),
    );

    const visible = computed(() => componentOptions.value.visible);

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      componentOptions,
      store,
      visible,
      t,
    };
  },
});
