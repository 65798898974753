
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/products/api/configuration/components/ComponentName';
import { setupComponent } from '@/products/composables/setupComponent';
import { DataType } from '@/products/api/configuration/application/DataType';

import InjectStyles from '@/common/components/InjectStyles.vue';
import VRuntimeTemplate from 'vue3-runtime-template';
export default defineComponent({
  name: ComponentName.searchSelection,

  components: {
    InjectStyles,
    VRuntimeTemplate,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();

    const { instance, componentName, isReady, isWebComponent, store, widerThan, t } =
      setupComponent(root, props.instanceId, [DataType.Products]);

    const componentOptions = computed(() => {
      return store.value?.options?.components?.selection;
    });
    const visible = computed(() => {
      return store.value?.options?.components?.selection?.visible;
    });

    return {
      root,
      instance,
      componentName,
      isReady,
      visible,
      isWebComponent,
      store,
      componentOptions,
      widerThan,
      t,
    };
  },
});
