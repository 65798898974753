
import { computed, defineComponent, ref } from 'vue';
import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { ClassificationItem, TreeTypeEnum } from '@/common/services/swagger/index.defs';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { ClassificationCommand } from '@/catalogs/api/runtime/CommandExecutor';
import { ISearchCategoriesOptions } from '@/catalogs/api/configuration/components/ISearchCategoriesOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import CategoriesList from '@/common/components/CategoriesList.vue';
import Card from '@/common/components/Card.vue';
import Message from '@/common/components/Message.vue';
import Arrow from '@/common/components/Arrow.vue';

export default defineComponent({
  name: ComponentName.searchPartCategories,

  components: {
    InjectStyles,
    LinkSecondary,
    Card,
    CategoriesList,
    Message,
    Arrow,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const trimList = ref(true);

    const { isReady, isWebComponent, componentName, instance, store, t } = setupComponent(
      root,
      props.instanceId,
      [DataType.ProductClassifications],
    );

    const componentOptions = computed(
      () =>
        store.value?.options.components?.searchPartCategories ?? ({} as ISearchCategoriesOptions),
    );

    const categories = computed(() => store.value?.data.partClassifications?.items ?? []);

    const visible = computed(() => componentOptions.value.visible);

    const categoriesTrimmed = computed(() =>
      trimList.value
        ? categories.value?.slice(0, componentOptions.value.maxCategoriesVisible)
        : categories.value,
    );

    const showMoreBtn = computed(
      () =>
        categories.value &&
        categories.value.length > (componentOptions.value.maxCategoriesVisible ?? 0),
    );

    const setCategory = ({ cid }: ClassificationItem) => {
      if (instance.value && cid) {
        instance.value.execute(
          new ClassificationCommand({ cid, treeType: TreeTypeEnum.Parts, clearSearch: false }),
        );
      }
    };

    const showAllCategories = () => {
      trimList.value = !trimList.value;
    };

    return {
      root,
      isReady,
      isWebComponent,
      componentName,
      componentOptions,
      trimList,
      store,
      visible,
      categories,
      categoriesTrimmed,
      showMoreBtn,
      t,
      setCategory,
      showAllCategories,
    };
  },
});
