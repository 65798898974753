
import { computed, defineComponent, ref, watch } from 'vue';

import { ComponentName } from '@/catalogs/api/configuration/components/ComponentName';
import { DataType } from '@/catalogs/api/configuration/application/DataType';
import { setupComponent } from '@/catalogs/composables/setupComponent';
import { FilterItem } from '@/common/services/swagger/index.defs';
import { ISearchFiltersOptions } from '@/catalogs/api/configuration/components/ISearchFiltersOptions';

import InjectStyles from '@/common/components/InjectStyles.vue';
import Badge from '@/common/components/Badge.vue';
import LinkSecondary from '@/common/components/LinkSecondary.vue';
import Spinner from '@/common/components/Spinner.vue';
import Btn from '@/common/components/Btn.vue';
import Clickable from '@/common/components/Clickable.vue';
import Arrow from '@/common/components/Arrow.vue';
import Card from '@/common/components/Card.vue';
import FiltersNavigator from '@/products/components/common/FiltersNavigatorModal.vue';
import ScrollableList from '@/common/components/ScrollableList.vue';
import Message from '@/common/components/Message.vue';

export default defineComponent({
  name: ComponentName.searchFilters,

  components: {
    InjectStyles,
    Spinner,
    Badge,
    LinkSecondary,
    Btn,
    Clickable,
    Arrow,
    Card,
    FiltersNavigator,
    ScrollableList,
    Message,
  },

  props: {
    instanceId: String,
  },

  setup(props) {
    const root = ref<HTMLElement>();
    const areFiltersDirty = ref(false);
    const allFiltersModalShown = ref(false);

    const { componentName, isReady, isWebComponent, instance, store, t, useProductsFilters } =
      setupComponent(root, props.instanceId, [DataType.Filters]);

    const componentOptions = computed(
      () => store.value?.options.components?.searchFilters ?? ({} as ISearchFiltersOptions),
    );

    const {
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersModel,
      filtersFetched,
      filtersLoading,
      areFiltersApplied,
      filteredResultCount,
      filteredResultCountLoading,
      filtersOpen,
      fetchFilterValues,
      applyModelFilters,
      resetFiltersApplied,
      refreshResultCount,
      refreshFetchedFilterValues,
      syncSelectedFilters,
      expandAllFilters,
      collapseAllFilters,
    } = useProductsFilters();

    const unwatch = watch(
      () => instance.value,
      () => {
        instance.value?.eventBus.on('search-changed', () => {
          setTimeout(() => {
            if (filtersFetched.size > 0) {
              refreshFetchedFilterValues();
              refreshResultCount();
            }
          }, 500);

          syncSelectedFilters();
        });
        unwatch();
      },
    );

    const trimFilters = (filters) =>
      filters.slice(0, componentOptions.value.maxFiltersVisible || 10) ?? [];

    const filtersToDisplay = computed(() =>
      filtersPromoted.value.length ? filtersPromoted.value : trimFilters(filtersAdditional.value),
    );

    const toggleFilter = async ({ attributeCode }: FilterItem) => {
      if (!attributeCode || !instance.value) return;

      if (filtersOpen.has(attributeCode)) {
        filtersOpen.delete(attributeCode);
      } else {
        if (!filtersFetched.has(attributeCode)) {
          await fetchFilterValues([attributeCode]);
        }

        filtersOpen.add(attributeCode);
      }
    };

    const isFilterOpen = ({ attributeCode }: FilterItem) => filtersOpen.has(attributeCode);
    const isFilterLoading = ({ attributeCode }: FilterItem) => filtersLoading.has(attributeCode);

    const showAllFilters = () => {
      // some issue with updating value from modal, workaround to trigger change on allFiltersModalShown
      allFiltersModalShown.value = false;
      setTimeout(() => {
        allFiltersModalShown.value = true;
      }, 1);
    };

    const clearAndCloseFilters = async () => {
      resetFiltersApplied().then(() => {
        if (filtersOpen) {
          filtersOpen.clear();
        }
      });
    };

    const onModalFilterValueChange = ({ attributeCode, filterValueName, value }) => {
      filtersModel.value[attributeCode][filterValueName] = value;
      refreshResultCount();
    };

    const onModalApplyFilters = () => {
      applyModelFilters();
      allFiltersModalShown.value = false;
    };

    const onFilterValueChange = () => {
      areFiltersDirty.value = true;
      refreshResultCount();
    };

    const expandAll = () => {
      expandAllFilters(filtersToDisplay.value);
    };

    const collapseAll = () => {
      collapseAllFilters();
    };

    return {
      root,
      componentName,
      componentOptions,
      isReady,
      isWebComponent,
      store,
      filtersAll,
      filtersPromoted,
      filtersAdditional,
      filtersToDisplay,
      filtersOpen,
      filtersFetched,
      areFiltersDirty,
      filtersModel,
      areFiltersApplied,
      allFiltersModalShown,
      filteredResultCount,
      filteredResultCountLoading,
      t,
      toggleFilter,
      clearAndCloseFilters,
      isFilterOpen,
      isFilterLoading,
      showAllFilters,
      applyModelFilters,
      fetchFilterValues,
      onModalFilterValueChange,
      onModalApplyFilters,
      onFilterValueChange,
      resetFiltersApplied,
      expandAll,
      collapseAll,
    };
  },
});
