import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["part"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_4 = { class: "row my-4" }
const _hoisted_5 = { class: "col-12 col-md-4 mb-4" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mt-4" }
const _hoisted_8 = { class: "col-12 col-md-8 order-md-first" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InjectStyles = _resolveComponent("InjectStyles")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_VRuntimeTemplate = _resolveComponent("VRuntimeTemplate")!
  const _component_ImageGallery = _resolveComponent("ImageGallery")!
  const _component_AttributesTable = _resolveComponent("AttributesTable")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "root",
    class: _normalizeClass(["pis-component", _ctx.componentName]),
    part: _ctx.componentName
  }, [
    (_ctx.isReady && _ctx.componentOptions.visible)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_ctx.isWebComponent)
            ? (_openBlock(), _createBlock(_component_InjectStyles, {
                key: 0,
                items: _ctx.store?.options.styles
              }, null, 8, ["items"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Modal, {
            show: _ctx.previewData,
            "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.previewData) = $event)),
            title: _ctx.title,
            xl: ""
          }, {
            "header-actions": _withCtx(() => [
              _createVNode(_component_Btn, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.item && _ctx.details(_ctx.item)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('ViewDetails')), 1)
                ]),
                _: 1
              })
            ]),
            default: _withCtx(() => [
              (_ctx.identifiers.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.identifiers, (identifier) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "text-muted",
                        key: identifier
                      }, _toDisplayString(identifier), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ImageGallery, {
                    "show-preview": "",
                    "hide-thumbnails": "",
                    "disable-modal": "",
                    images: _ctx.item?.images,
                    "show-overlay-arrows": true,
                    title: _ctx.t('ProductGallery')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.componentOptions.imageOverlayTemplate)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                            _createVNode(_component_VRuntimeTemplate, {
                              template: _ctx.componentOptions.imageOverlayTemplate,
                              "template-props": { instance: _ctx.instance, item: _ctx.item, emit: _ctx.emit, t: _ctx.t }
                            }, null, 8, ["template", "template-props"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["images", "title"]),
                  _createElementVNode("div", _hoisted_7, [
                    (_ctx.componentOptions.actionTemplate)
                      ? (_openBlock(), _createBlock(_component_VRuntimeTemplate, {
                          key: 0,
                          template: _ctx.componentOptions.actionTemplate,
                          "template-props": {
                    instance: _ctx.instance,
                    item: _ctx.item,
                    emit: _ctx.emit,
                    details: _ctx.details,
                    attrValue: _ctx.attrValue,
                    attrValues: _ctx.attrValues,
                    close: _ctx.close,
                    t: _ctx.t,
                  }
                        }, null, 8, ["template", "template-props"]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_component_AttributesTable, {
                    instance: _ctx.instance,
                    attributes: _ctx.attributes,
                    "hide-document-links": _ctx.hideDocumentLinks,
                    t: _ctx.t
                  }, null, 8, ["instance", "attributes", "hide-document-links", "t"])
                ])
              ])
            ]),
            _: 1
          }, 8, ["show", "title"])
        ]))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}